<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ForgotPassword',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      resetPasswordEmail: '',
      isLoading: false,
      error: null,
      success: null,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    async confirmResetPassword() {
      this.isLoading = true;

      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        return;
      }

      const res = await this.resetPassword({
        email: this.resetPasswordEmail,
      });

      switch (res.status) {
        case 422:
          this.error = res.data.message;
          break;
        case 404:
          this.error = 'Email address not found';
          break;
        case 400:
          this.error = 'Sorry, system is busy. Please try again later.';
          break;
        default:
          this.success = 'Your password is reset. Please check your email for the new password.';
          break;
      }

      if (this.error) {
        this.$gtag.event('reset-password', {
          'event_category': 'experience',
          'event_label': 'Reset Password (error)',
          'event_value': this.error,
        });
        setTimeout(() => this.error = null, 5000);
      } else if (this.success) {
        setTimeout(() => this.success = null, 8000);
        this.resetPasswordEmail = null;
      }

      this.isLoading = false;
    },
  },
  mounted() {
  },
};
</script>
<template>
  <v-sheet 
    class="forgotpassword-body-sheet mx-auto pt-6"
    elevation="0"
  >
    <ValidationObserver ref="form">
      <form>
        <v-card color="#f7efe8" elevation="0">
          <v-card-title class="justify-center h4 font-weight-bold pb-2">
            <span style="color:#122944;">Forgot</span> <span class="tertiary--text ml-2">Password?</span>
          </v-card-title>
          <v-card-subtitle class="justify-center text-caption text-justify font-weight-bold px-10 mt-8 mb-4">
            Enter the email address associated with your Dayone account and we will send you an email with new random generated password.
          </v-card-subtitle>
          <v-card-text class="text-center pb-3">
            <v-row class="px-3">
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="resetPasswordEmail"
                    class="p-0 m-0"
                    placeholder="E-mail Address"
                    label="Email"
                    color="primary"
                    autocomplete="email"
                    height="30"
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center px-3 mt-3">
              <v-col cols="6" class="pl-10">
                <v-btn
                  :disabled="isLoading"
                  class="forgotpassword-btn-back text-caption font-weight-6 white--text text-decoration-none py-4"
                  block
                  :to="{ name: 'login' }"
                >
                  <v-icon small class="mr-1">mdi-arrow-left</v-icon>Back
                </v-btn>
              </v-col>
              <v-col cols="6" class="pr-10">
                <v-btn
                  :disabled="isLoading"
                  color=""
                  class="forgotpassword-btn-reset text-caption font-weight-6 white--text py-4"
                  block
                  @click="confirmResetPassword()"
                >
                  <v-icon small class="mr-1">mdi-reset</v-icon>Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </form>
      <v-alert
        v-if="error"
        class="alert-global text-body-2 white--text p-2"
        type="error"
        transition="fade-transition"
        dense
        >{{ error }}</v-alert
      >
      <v-alert
        v-if="success"
        type="success"
        class="text-caption text-left"
        transition="fade-transition"
        dense
        >{{ success }}</v-alert
      >
    </ValidationObserver>
  </v-sheet>
</template>
<style scoped>
.forgotpassword-body-sheet {
  background-color: #f7efe8;
  height: 100dvh;
}

.forgotpassword-body-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

.forgotpassword-body-sheet /deep/ .tnc .v-label {
  font-size: 12px !important;
  font-weight: bold;
  color: var(--v-dark);
  margin-top: 7px;
}

.forgotpassword-body-sheet /deep/ .forgotpassword-btn-back,
.forgotpassword-body-sheet /deep/ .forgotpassword-btn-reset {
  background-color: var(--v-buttonBackground) !important;
  color: #fff !important;
  z-index: 1;
}

.alert-global {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>